import { call, put, takeLatest } from "redux-saga/effects";
import schoolReducer from "../reducers/school.reducer";
import ApiServices from "../../graphqlApiServices";
import axios from "axios";
import userReducer from "../reducers/user.reducer";

const { schools, user } = ApiServices

const { getSchools, getschoolById, getSchoolByState, createSchool, updateSchool, deactivateSchool, activateSchool, updatePackage, releaseReport, uploadPhoto } = schools
const { getUserByEmail } = user;


export function* watchSchoolRequests() {
    yield takeLatest(schoolReducer.actions.getSchoolsRequest, getSchoolsSaga);
    yield takeLatest(schoolReducer.actions.getSchoolbyIdRequest, getSchoolByIdSaga);
    yield takeLatest(schoolReducer.actions.getSchoolbyStateRequest, getSchoolByStateSaga);
    yield takeLatest(schoolReducer.actions.createSchoolRequest, createSchoolSaga);
    yield takeLatest(schoolReducer.actions.updateSchoolRequest, updateSchoolSaga);
    yield takeLatest(schoolReducer.actions.deactivateSchoolRequest, deactivateSchoolSaga)
    yield takeLatest(schoolReducer.actions.activateSchoolRequest, activateSchoolSaga)
    yield takeLatest(schoolReducer.actions.updatePackageRequest, updatePackageSaga)
    yield takeLatest(schoolReducer.actions.releaseReportRequest, releaseReportSaga)
    yield takeLatest(schoolReducer.actions.uploadPhotoRequest, uploadPhotoSaga)
}

export function* getSchoolsSaga(payload) {
    try {
        const response = yield call(getSchools, payload);
        const data = response.data.getSchools.schools
        yield put(schoolReducer.actions.getSchoolsScuccess(data))
        if (response?.data?.getSchools?.nextToken) {
                    const params = {
                        nextToken: response?.data?.getSchools?.nextToken
                    }
                    yield put(schoolReducer.actions.getSchoolsRequest(params))
                }
    } catch (error) {
        if (error.data?.getSchools?.schools?.length > 0) {
            const data = error.data.getSchools.schools
            yield put(schoolReducer.actions.getSchoolsScuccess(data))
            if(error?.data?.getSchools?.nextToken){
                const params = {
                    nextToken: error?.data?.getSchools?.nextToken
                }
                yield put(schoolReducer.actions.getSchoolsRequest(params))
            }
        } else {
            yield put(schoolReducer.actions.getSchoolsFail({ error: error.errors[0] }))
        }
    }
}

export function* getSchoolByIdSaga(payload) {
    try {
        const response = yield call(getschoolById, payload);
        const data = response.data.getSchoolById

        yield put(schoolReducer.actions.getSchoolbyIdScuccess({ data }))
    } catch (error) {

        if (error.data.getschoolById && Object.keys(error.data.getschoolById).length > 0) {
            yield put(schoolReducer.actions.getSchoolbyIdScuccess({ data: error.data.getSchoolById }))
        } else {
            yield put(schoolReducer.actions.getSchoolsFail({ error }))
        }
    }
}

export function* getSchoolByStateSaga(payload) {
    try {
        const response = yield call(getSchoolByState, payload);
        const data = response.data.getSchoolsByState.schools

        yield put(schoolReducer.actions.getSchoolbyStateScuccess({ data }))
    } catch (error) {
        if (error.data?.getSchoolsByState?.schools?.length > 0) {
            const data = error.data.getSchoolsByState.schools
            yield put(schoolReducer.actions.getSchoolbyStateScuccess({ data }))
        } else {
            yield put(schoolReducer.actions.getSchoolsFail({ error }))
        }
    }
}

export function* createSchoolSaga(payload) {
    try {
        const userResponse = yield call(getUserByEmail, payload.payload.schoolAdmin.email)
        
        
        if(!userResponse?.data?.getUserByEmail?.userId){
            const response = yield call(createSchool, payload);
            const data = response.data.createSchool
            yield put(schoolReducer.actions.createSchoolScuccess({ data }))
            const na = payload.payload.schoolAdmin.name.split(" ")
            const formData = {
                email: payload.payload.schoolAdmin.email,
                gender: na[0] === "Mr." ? "Male" : "Female",
                firstName: na[1],
                lastName: na[2],
                role: "school-admin",
                schoolId: data?.schoolId,
                salutation: na[0],
                phone: data?.schoolAdmin?.phone
            }
            const logoData = {
                schoolId: data?.schoolId,
                schoolCode: data?.schoolCode,
                logo: payload.payload?.logo
            }
            yield put(schoolReducer.actions.uploadPhotoRequest(logoData))
            yield put(userReducer.actions.createUserRequest(formData))
        }
        else {
            yield put(schoolReducer.actions.createSchoolFail())
        }
        // setTimeout(() => window.history.back(), 2000)
    } catch (error) {
        if (error?.data?.createSchool) {
            const data = error.data.createSchool;
            yield put(schoolReducer.actions.createSchoolScuccess({ data }))
            const na = payload.payload.schoolAdmin.name.split(" ")
            const formData = {
                email: payload.payload.schoolAdmin.email,
                gender: na[0] === "Mr." ? "Male" : "Female",
                firstName: na[1],
                lastName: na[2],
                role: "school-admin",
                schoolId: data?.schoolId,
                salutation: na[0],
                phone: data?.schoolAdmin?.phone
            }
            const logoData = {
                schoolId: data?.schoolId,
                schoolCode: data?.schoolCode,
                logo: payload.payload?.logo
            }
            yield put(schoolReducer.actions.uploadPhotoRequest(logoData))
            yield put(userReducer.actions.createUserRequest(formData))
            // setTimeout(() => window.history.back(), 2000)
        }
        else {

            yield put(schoolReducer.actions.createSchoolFail({ error }))
        }
    }
}

export function* uploadPhotoSaga(payload) {
    try {
        const response = yield call(uploadPhoto, payload);
        const data = response.data.uploadSchoolLogo

        yield put(schoolReducer.actions.uploadPhotoSuccess({ data }))
        // setTimeout(() => window.history.back(), 2000)
    } catch (error) {
        
        if (error.data) {
            const data = error.data.uploadSchoolLogo;
            yield put(schoolReducer.actions.uploadPhotoSuccess({ data }))
            // setTimeout(() => window.history.back(), 2000)
        } else {
            yield put(schoolReducer.actions.uploadPhotoFail({ error: error }))
        }
    }
}

export function* updateSchoolSaga(payload) {
    try {
        const response = yield call(updateSchool, payload);
        const data = response.data.updateSchool

        const logoData = {
            schoolId: data?.schoolId,
            schoolCode: data?.schoolCode,
            logo: payload.payload?.logo
        }
        yield put(schoolReducer.actions.uploadPhotoRequest(logoData))
        yield put(schoolReducer.actions.updateSchoolSuccess({ data }))
        setTimeout(() => window.history.back(), 2000)
    } catch (error) {
        if (error.data) {
            const data = error.data.updateSchool;
            const logoData = {
                schoolId: data?.schoolId,
                schoolCode: data?.schoolCode,
                logo: payload.payload?.logo
            }
            yield put(schoolReducer.actions.uploadPhotoRequest(logoData))
            yield put(schoolReducer.actions.updateSchoolSuccess({ data }))
            setTimeout(() => window.history.back(), 2000)
        } else {
            yield put(schoolReducer.actions.updateSchoolFail({ error: error }))
        }
    }
}

export function* deactivateSchoolSaga(payload) {

    try {
        const response = yield call(deactivateSchool, payload);
        const data = response.data.deactivateSchool
        yield put(schoolReducer.actions.deactivateSchoolSuccess({ data }))
        yield put(schoolReducer.actions.getSchoolbyIdRequest({ schoolId: payload.payload?.schoolId }))
    } catch (error) {

        yield put(schoolReducer.actions.deactivateSchoolFail({ error }))
    }
}

export function* activateSchoolSaga(payload) {
    try {
        const response = yield call(activateSchool, payload);
        const data = response.data.activateSchool

        yield put(schoolReducer.actions.activateSchoolSuccess({ data }))
        yield put(schoolReducer.actions.getSchoolbyIdRequest({ schoolId: payload.payload?.schoolId }))
    } catch (error) {

        yield put(schoolReducer.actions.activateSchoolFail({ error }))
    }
}

export function* updatePackageSaga(payload) {
    try {
        const response = yield call(updatePackage, payload);
        const data = response?.data?.updatePackage

        yield put(schoolReducer.actions.updateSchoolSuccess({ data }))
        yield put(schoolReducer.actions.getSchoolbyIdRequest({ schoolId: payload?.payload?.schoolId }))
    } catch (error) {
        if (error.data) {
            const data = error.data.updatePackage;
            yield put(schoolReducer.actions.updateSchoolSuccess({ data }))
        }else{  
            yield put(schoolReducer.actions.updateSchoolFail({ error: error }))
        }
    }
}

export function* releaseReportSaga(payload) {
    try {
        const response = yield call(releaseReport, payload);
        const data = response.data.updateSchool

        yield put(schoolReducer.actions.releaseReportSuccess({ data }))
        yield put(schoolReducer.actions.getSchoolsRequest())
    } catch (error) {
        if (error.data) {
            const data = error.data.updateSchool;
            yield put(schoolReducer.actions.releaseReportSuccess({ data }))
            yield put(schoolReducer.actions.getSchoolsRequest())
        } else {


            yield put(schoolReducer.actions.releaseReportFail({ error: error }))
        }
    }
}
