import { API, graphqlOperation } from "aws-amplify";
import Cookies from "js-cookie";

function getSchools(payload) {
  const token = Cookies.get("tokenId");
  const nextToken = payload?.payload?.nextToken ? `"${payload?.payload?.nextToken}"` : null;
  return API.graphql({
    query: `query MyQuery {
      getSchools(count: 100, nextToken: ${nextToken}) {
        nextToken
        schools {
          createdAt
          isReport
          isClinic
          classMap
          url
          screeningPackageDetails {
            abdomen
            behavioralScreening
            dental
            ear
            eye
            hair
            heart
            learningScreening
            lungs
            skin
            vitalSigns
            throat
          }
          strength
          state
          staffAndAmenities {
            amenitiesAvailable
            counsellors {
              counsellorsCount
            }
            infirmary {
              doctor
              nurse
            }
            teachers
          }
          screeningTo
          screeningPlan
          screeningFrom
          schoolName
          schoolId
          schoolCode
          schoolAdmin {
            email
            name
            phone
            userId
          }
          pincode
          organization
          name
          isActive
          establishedFrom
          address
          annualOrientationMonth
          package
          board
          city
          contact {
            email
            mobNumber
            name
            officeNumber
          }
        }
      }
    }`,
    authToken: token,
  });
}

function getschoolById(payload) {
  const token = Cookies.get("tokenId");

  return API.graphql({
    query: `query MyQuery {
      getSchoolById(schoolId: "${payload?.payload.schoolId}") {
        isReport
        isClinic
        classMap
        isBatchUpload
        address
        annualOrientationMonth
        board
        city
        contact {
          email
          mobNumber
          name
          officeNumber
        }
        createdAt
        establishedFrom
        isActive
        name
        organization
        package
        pincode
        schoolAdmin {
          phone
          name
          email
          userId
          salutation
        }
        schoolCode
        schoolId
        schoolName
        screeningFrom
        screeningPlan
        screeningPackageDetails {
          abdomen
          behavioralScreening
          dental
          ear
          eye
          hair
          heart
          learningScreening
          lungs
          skin
          throat
          vitalSigns
        }
        screeningTo
        staffAndAmenities {
          amenitiesAvailable
          counsellors {
            counsellorsAvailable
            counsellorsCount
          }
          infirmary {
            doctor
            infirmaryAvailable
            nurse
          }
          teachers
        }
        state
        strength
        updatedAt
        url
      }
    }`,
    authToken: token,
  });
}


function getSchoolByState(payload) {
  console.log(payload, 'payload state')
  const token = Cookies.get("tokenId");
  return API.graphql({
    query: `query MyQuery {
      getSchoolsByState(state: "${payload.payload.state}") {
        schools {
          createdAt
          isReport
          isClinic
          classMap
          url
          screeningPackageDetails {
            abdomen
            behavioralScreening
            dental
            ear
            eye
            hair
            heart
            learningScreening
            lungs
            skin
            vitalSigns
            throat
          }
          strength
          state
          staffAndAmenities {
            amenitiesAvailable
            counsellors {
              counsellorsCount
            }
            infirmary {
              doctor
              nurse
            }
            teachers
          }
          screeningTo
          screeningPlan
          screeningFrom
          schoolName
          schoolId
          schoolCode
          schoolAdmin {
            email
            name
            phone
            userId
          }
          pincode
          organization
          name
          isActive
          establishedFrom
          address
          annualOrientationMonth
          package
          board
          city
          contact {
            email
            mobNumber
            name
            officeNumber
          }
        }
      }
    }`,
    authToken: token,
  });
}

function createSchool(payload) {

  const token = Cookies.get("tokenId");
  const params = payload.payload;

  return API.graphql(graphqlOperation(`
    mutation MyMutation ($ear: [String], $abdomen: [String], $behavioralScreening :[String], $dental: [String], $eye: [String], $hair: [String], $heart: [String], $lungs: [String], $learningScreening: [String], $skin: [String], $throat: [String], $vitalSigns: [String]) {
      createSchool(city: "${params.city}", name: "${params.name}", pincode: ${params.pincode}, schoolAdmin: {name: "${params.schoolAdmin.name}", email: "${params.schoolAdmin.email}", phone: "${params.schoolAdmin.phone}"}, schoolName: "${params.schoolName}", state: "${params.state}", address: "${params.address}", annualOrientationMonth: "${params.annualOrientationMonth}", board: ${params.board}, contact: {mobNumber: "${params.contact.mobNumber}", email: "${params.contact.email}", name: "${params.name}", officeNumber: "${params.contact.officeNumber}"}, establishedFrom: "${params.establishedFrom}", organization: ${params.organization}, schoolCode: "${params.schoolCode}", screeningFrom: "${params.screeningFrom}", screeningPlan: ${params.screeningPlan}, screeningTo: "${params.screeningTo}", staffAndAmenities: {amenitiesAvailable: "${params.staffAndAmenities.amenitiesAvailable}", counsellors: {counsellorsCount: ${params.staffAndAmenities.counsellors}}, infirmary: {nurse: ${params.nurse}, doctor: ${params.doctor}}, teachers: "${params.staffAndAmenities.teachers}"}, strength: ${params.strength}, isClinic: ${params.isClinic} package: ${params.planName}, screeningPackageDetails: {abdomen: $abdomen, behavioralScreening: $behavioralScreening, dental: $dental, ear: $ear, eye: $eye, hair: $hair, heart: $heart, lungs: $lungs, learningScreening: $learningScreening, skin: $skin, throat: $throat, vitalSigns: $vitalSigns}) {
        address
        annualOrientationMonth
        board
        city
        screeningPackageDetails {
          abdomen
          behavioralScreening
          dental
          ear
          eye
          hair
          heart
          learningScreening
          lungs
          skin
          vitalSigns
          throat
        }
        contact {
          email
          mobNumber
          name
          officeNumber
        }
        createdAt
        isActive
        establishedFrom
        name
        organization
        pincode
        schoolAdmin {
          email
          name
          phone
        }
        schoolCode
        schoolId
        schoolName
        screeningFrom
        screeningPlan
        screeningTo
        staffAndAmenities {
          amenitiesAvailable
          counsellors {
            counsellorsCount
          }
          infirmary {
            doctor
            nurse
          }
          teachers
        }
        strength
        state
        updatedAt
        url
      }
    }`, { ear: params?.ear, abdomen: params?.abdomen, behavioralScreening: params?.behavoral, dental: params?.dental, eye: params?.eye, hair: params?.hair, heart: params?.heart, lungs: params?.lungs, learningScreening: params?.learning, skin: params?.skin, throat: params?.throat, vitalSigns: params?.vitalSigns }, token
  ));
}

function updateSchool(payload) {
  const token = Cookies.get("tokenId");
  const params = payload.payload;


  return API.graphql(graphqlOperation(
    `mutation MyMutation ($ear: [String], $abdomen: [String], $behavioralScreening :[String], $dental: [String], $eye: [String], $hair: [String], $heart: [String], $lungs: [String], $learningScreening: [String], $skin: [String], $throat: [String], $vitalSigns: [String]) {
      updateSchool(city: "${params.city}", name: "${params.name}", pincode: ${params.pincode}, schoolAdmin: {name: "${params.schoolAdmin.name}", email: "${params.schoolAdmin.email}", phone: "${params.schoolAdmin.phone}"}, schoolName: "${params.schoolName}", state: "${params.state}", address: "${params.address}", annualOrientationMonth: "${params.annualOrientationMonth}", board: ${params.board}, contact: {mobNumber: "${params.contact.mobNumber}", email: "${params.contact.email}", name: "${params.name}", officeNumber: "${params.contact.officeNumber}"}, establishedFrom: "${params.establishedFrom}", organization: ${params.organization}, schoolCode: "${params.schoolCode}", screeningFrom: "${params.screeningFrom}", screeningPlan: ${params.screeningPlan}, screeningTo: "${params.screeningTo}", staffAndAmenities: {amenitiesAvailable: "${params.staffAndAmenities.amenitiesAvailable}", counsellors: {counsellorsCount: ${params.staffAndAmenities.counsellors.counsellorsCount}}, infirmary: {nurse: ${params.staffAndAmenities.infirmary.nurse}, doctor: ${params.staffAndAmenities.infirmary.doctor}}, teachers: "${params.staffAndAmenities.teachers}"}, strength: ${params.strength}, package: ${params.planName}, schoolId : "${params.schoolId}", screeningPackageDetails: {abdomen: $abdomen, behavioralScreening: $behavioralScreening, dental: $dental, ear: $ear, eye: $eye, hair: $hair, heart: $heart, lungs: $lungs, learningScreening: $learningScreening, skin: $skin, throat: $throat, vitalSigns: $vitalSigns}){
        address
        annualOrientationMonth
        board
        city
        screeningPackageDetails {
          abdomen
          behavioralScreening
          dental
          ear
          eye
          hair
          heart
          learningScreening
          lungs
          skin
          vitalSigns
          throat
        }
        contact {
          email
          mobNumber
          name
          officeNumber
        }
        createdAt
        isActive
        establishedFrom
        name
        organization
        pincode
        schoolAdmin {
          email
          name
          phone
        }
        schoolCode
        schoolId
        schoolName
        screeningFrom
        screeningPlan
        screeningTo
        staffAndAmenities {
          amenitiesAvailable
          counsellors {
            counsellorsCount
          }
          infirmary {
            doctor
            nurse
          }
          teachers
        }
        strength
        state
        updatedAt
        url
      }
    }`,
    { ear: params?.ear, abdomen: params?.abdomen, behavioralScreening: params?.behavoral, dental: params?.dental, eye: params?.eye, hair: params?.hair, heart: params?.heart, lungs: params?.lungs, learningScreening: params?.learning, skin: params?.skin, throat: params?.throat, vitalSigns: params?.vitalSigns }, token
  ));
}

function deactivateSchool(payload) {
  const token = Cookies.get("tokenId");
  // console.trace(.{})
  // 
  return API.graphql({
    query: `mutation MyMutation {
                updateSchool(
                  schoolCode: "${payload.payload.schoolCode}",
                  schoolId: "${payload.payload.schoolId}",
                  isActive : false
                ) {
                  board
                  organization
                  annualOrientationMonth
                  establishedFrom
                  screeningPlan
                  screeningFrom
                  screeningTo
                  url
                  address
                  strength
                  createdAt
                  updatedAt
                  isActive
                }
}`,
    authToken: token,
  });
}

function activateSchool(payload) {
  const token = Cookies.get("tokenId");
  // 
  return API.graphql({
    query: `mutation MyMutation {
      updateSchool(
        schoolCode: "${payload.payload.schoolCode}",
        schoolId: "${payload.payload.schoolId}",
        isActive : true
      ) {
        board
        organization
        annualOrientationMonth
        establishedFrom
        screeningPlan
        screeningFrom
        screeningTo
        url
        address
        strength
        createdAt
        updatedAt
        isActive
      }
}`,
    authToken: token,
  });
}

function updatePackage(payload) {
  const token = Cookies.get("tokenId");

  const params = payload.payload
  return API.graphql(graphqlOperation(
    `mutation MyMutation ($ear: [String], $abdomen: [String], $behavioralScreening :[String], $dental: [String], $eye: [String], $hair: [String], $heart: [String], $lungs: [String], $learningScreening: [String], $skin: [String], $throat: [String], $vitalSigns: [String]) {
      updatePackage(packageDetail: {abdomen: $abdomen, behavioralScreening: $behavioralScreening , dental: $dental, ear: $ear, eye: $eye, hair: $hair, heart: $heart, learningScreening: $learningScreening, lungs: $lungs, skin: $skin, throat: $throat, vitalSigns: $vitalSigns}, schoolId: "${params.schoolId}", schoolCode: "${params.schoolCode}" packageName: ${params.tempPlanName}) {
        status
        message
      }
    }`,
    { ear: params?.ear, abdomen: params?.abdomen, behavioralScreening: params?.behavoral, dental: params?.dental, eye: params?.eye, hair: params?.hair, heart: params?.heart, lungs: params?.lungs, learningScreening: params?.learning, skin: params?.skin, throat: params?.throat, vitalSigns: params?.vitalSigns }, token,
  ));
}

function releaseReport(payload) {
  const token = Cookies.get("tokenId");
  // 
  return API.graphql({
    query: `mutation MyMutation {
      updateSchool(
        schoolCode: "${payload.payload.schoolCode}",
        schoolId: "${payload.payload.schoolId}",
        isReport : true
      ) {
        board
        organization
        annualOrientationMonth
        establishedFrom
        screeningPlan
        screeningFrom
        screeningTo
        url
        isReport
        address
        strength
        createdAt
        updatedAt
        isActive
      }
}`,
    authToken: token,
  });
}

function uploadPhoto(payload) {
  const token = Cookies.get("tokenId");
  const params = payload.payload

  return API.graphql(graphqlOperation(
    `mutation MyMutation($logo: AWSJSON!) {
      uploadSchoolLogo(data: $logo, schoolCode: "${params?.schoolCode}", schoolId: "${params?.schoolId}")
    }`,
    { logo: params?.logo },
    token,
  ));
}


function getBulkUploadStatus(schoolId, callBack) {
  const token = Cookies.get("tokenId");
  return API.graphql(graphqlOperation(
    `query MyQuery(
      $si : String!
    ){
      getSchoolById(schoolId: $si) {
        isBatchUpload
      }
    }
    `,
    { si: schoolId },
    token,
  )).then((data) => {
    callBack('success', data.data.getSchoolById)
  }).catch((error) => {
    callBack('failure', error);
  })
}

const schools = {
  getSchools,
  getschoolById,
  getSchoolByState,
  createSchool,
  updateSchool,
  deactivateSchool,
  activateSchool,
  updatePackage,
  releaseReport,
  uploadPhoto,
  getBulkUploadStatus
};

export default schools;
