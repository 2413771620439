import React, { useEffect, useState } from 'react'
import schoolReducer from '../../../redux/reducers/school.reducer'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Avatar, FormControl, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { stringAvatar } from '../../../utils/utility';
import BlankDataScreen from '../../BlankDataScreen/BlankDataScreen';
import * as XLSX from 'xlsx';
import classes from '../../AnalyticsPage/MissingDataAnalytics.module.css';
import screening from '../../../graphqlApiServices/screening';

const issueKeys = {
    dental: "Dental",
    leftEar: "Left Ear",
    rightEar: "Right Ear",
    throat: "Throat",
    eye: "Eye",
    heart: "Heart",
    lungs: "Lungs",
    skin: "Skin"
}

function DataQuality() {
    const dispatch = useDispatch()

    const getSchools = schoolReducer.actions.getSchoolsRequest;
    const { getQualityIssues } = screening;
    const schools = useSelector((state) => state.schoolReducer.data)

    const [selectedSchool, setSelectedSchool] = useState('')
    const [selectedClass, setSelectedClass] = useState('')
    const [qualityIssueData, setQualityIssueData] = useState([])
    const [classList, setClassList] = useState([])

    const handleDownload = () => {
        // const excelData = []
        if(!qualityIssueData.length) return;
        const data = qualityIssueData.map((a) => {
            return {
                ...a,
                dataQuality: JSON.stringify(a?.dataQuality)
            }
        })
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "DataSheet.xlsx");
    }

    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        if (selectedSchool) {
            const x = JSON.parse(selectedSchool?.classMap)
            const y = Object.keys(x)
            const cls = []
            for (let i = 0; i < y?.length; i++) {
                cls.push(y[i]?.split("_").join("-"))
            }
            cls.sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))
            setClassList(cls)
        }
    }, [selectedSchool])

    useEffect(() => {
        dispatch(getSchools())
    }, [])

    useEffect(() => {
        if (selectedSchool) {
            getQualityIssues({
                schoolId: selectedSchool?.schoolId,
            }).then((res) => {
                setQualityIssueData(res?.data?.getDataQuality);
            });
        }
    }, [selectedSchool])

    const getColor = (x) => {
        switch (x) {
            case 'PATIENT_CREATED':
                return '#898A8D'
            case 'PATIENT_ADDED':
                return "#E09F26"
            case 'SCREENING_DONE':
                return '#149457'
            case 'ASSIGN_VALIDATION':
                return '#FFFFFF'
            case 'VALIDATION_DONE':
                return '#1740A1'
            default:
                return '#D95A59'
        }
    }

    const getStatus = (x) => {
        switch (x) {
            case 'PATIENT_CREATED':
                return 'Student Created'
            case 'PATIENT_ADDED':
                return "Scheduled"
            case 'SCREENING_DONE':
                return 'Screened'
            case 'ASSIGN_VALIDATION':
                return 'Validation Pending'
            case 'VALIDATION_DONE':
                return 'Validated'
            default:
                return 'Unavailable'
        }
    }

    const getTextColor = (x) => {
        switch (x) {
            case 'PATIENT_CREATED':
                return '#FFFFFF'
            case 'PATIENT_ADDED':
                return "#FFFFFF"
            case 'SCREENING_DONE':
                return '#FFFFFF'
            case 'ASSIGN_VALIDATION':
                return '#343434'
            case 'VALIDATION_COMPLETE':
                return '#FFFFFF'
            default:
                return '#FFFFFF'
        }
    }

    return (
        <div>
            <div>
                Data Quality Analytics
            </div>
            <div style={{ marginTop: 20, display: "flex", gap: 10, justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", gap: 10 }}>
                    <FormControl>
                        {/* <InputLabel id="demo-simple-select-label">School</InputLabel> */}
                        <Autocomplete
                            size='small'
                            options={schools}
                            value={selectedSchool}
                            // disableCloseOnSelect
                            sx={{ width: 250 }}
                            // placeholder={"School"}
                            renderInput={(params) =>
                                <TextField
                                    size='small'
                                    {...params}
                                    label={"School"}
                                    InputLabelProps={{ style: { fontSize: 15 } }}
                                />
                            }
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    {option.schoolName}
                                </li>
                            )}
                            getOptionLabel={(x) => {
                                return x?.schoolName ?? ""
                            }}
                            onChange={(e, value) => { setSelectedSchool(value) }}

                        />
                    </FormControl>
                    {/* <FormControl>
                        <InputLabel id="demo-simple-select-label" size='small'>Class</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedClass}
                            label="Class"
                            onChange={(e) => setSelectedClass(e.target.value)}
                            size='small'
                            disabled={!selectedSchool}
                            sx={{ width: 150 }}
                        >
                            {
                                classList?.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item}>
                                            {item}
                                        </MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl> */}
                </div>
                <div>
                    <button
                        style={{ cursor: "pointer", padding: 10, borderRadius: 10, backgroundColor: "#1740A1", color: "#fff", fontWeight: 600, border: "none", height: "fit-content", alignItems: "center" }}
                        onClick={handleDownload}
                    >
                        Download Data
                    </button>
                </div>
            </div>
            {(qualityIssueData && selectedSchool) ? <>
                <TableContainer sx={{ marginTop: "30px" }} component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead sx={{ backgroundColor: "#E8E7E7" }}>
                            <TableRow>
                                <TableCell>Student Name</TableCell>
                                <TableCell align="right">Class</TableCell>
                                <TableCell align="right">Status</TableCell>
                                <TableCell align="right" sx={{ minWidth: "350px" }}>Quality Issues</TableCell>
                                <TableCell align="right" sx={{ minWidth: "350px" }}>Screened By</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(qualityIssueData) && qualityIssueData?.slice(10 * (page - 1), 10 * page)?.map((row) => (
                                <TableRow
                                    key={row.studentName}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                                            <Avatar {...stringAvatar(`${row?.studentName} `)} /> {row?.studentName}
                                        </div>
                                    </TableCell>
                                    <TableCell align="right" style={{ color: "inherit" }}>{row.class}</TableCell>
                                    <TableCell align="right"><div style={{ backgroundColor: getColor(row?.status), color: getTextColor(row?.status), margin: 'auto' }} className={row?.status ? `${classes.screened_btn}` : `${classes.report_btn}`}>{getStatus(row?.status)}</div></TableCell>
                                    <TableCell align="right">{Object.keys(row?.dataQuality).map((x, index) => row?.dataQuality[x] !== null && (
                                        <span style={{ color: row?.dataQuality[x] === "BAD" ? "red" : "green" }}>{issueKeys[x]} {index < Object.keys(row?.dataQuality).length - 1 ? ", " : ""}</span>
                                    ))}</TableCell>
                                    <TableCell align="right">{Object.keys(row?.screenedBy).map((x, index) => row?.dataQuality[x] !== null && (
                                        <span>{issueKeys[x]}: {row?.screenedBy[x]} {index < Object.keys(row?.screenedBy).length - 1 ? ", " : ""}</span>
                                    ))}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
                    <Pagination count={(Math.ceil(qualityIssueData?.length / 10))} page={page} onChange={handleChange} />
                </div>
            </> :
                <div style={{ marginTop: 30 }}>
                    <BlankDataScreen
                        title="Oops! No data available"
                        description="Please select the above filters or try changing them."
                        hideButton={true}
                    />
                </div>
            }
        </div>
    )
}

export default DataQuality;