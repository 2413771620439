import { Alert, Box, Button, Modal, Snackbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import cssClasses from './OpsOperatorModal.module.css'
import InputDropdown from '../../../../common/InputDashboard/InputDropdown';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from 'react-top-loading-bar';
import screeningReducer from "../../../../../redux/reducers/screening.reducer";

function OpsOperatorModal({ handleClose, open, data }) {
    const higoIntegrationCall = screeningReducer.actions.higoInteReq;
    const operatorsList = useSelector((state) => state.screeningReducer.operators)
    const getOperator = screeningReducer.actions.getOperatorReq;
    const [Operator, setOperator] = useState('');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "fit-content",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 5
    };

    const dispatch = useDispatch()
    const [progress, setProgress] = useState(0)
    const [openSnackBar, setOpenSnackBar] = useState(false);


    const handleSubmit = () => {
        let operator = operatorsList?.find(obj => obj.name === Operator);
        const params = {
            ...data,
            operator: operator.id
        }
        
        dispatch(higoIntegrationCall(params))
        handleClose();
    }


    useEffect(() => {
        dispatch(getOperator());
    }, [])


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    return (
        <>
            <LoadingBar
                color='#1740A1'
                height={4}
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ backdropFilter: "blur(2px)" }}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" mb={5}>
                        Select Higo Operator
                    </Typography>
                    <div className={cssClasses.mainContainer}>
                        <div className={cssClasses.inputDiv}>
                            <InputDropdown
                                placeholder={"Select Operator"}
                                width={"25vw"}
                                bgColor="#ffffff"
                                value={Operator}
                                margin={1}
                                setValue={setOperator}
                                defaultValues={operatorsList ? Array.from(operatorsList, x => x?.name) ?? ['Fetching operators...'] : []}
                            />
                        </div>
                    </div>
                    <div className={cssClasses.buttonDiv}>
                        <button className={cssClasses.CancelButton} onClick={handleClose}>Cancel</button>
                        <button className={cssClasses.sendButton} onClick={handleSubmit}>Put data</button>
                    </div>
                </Box>
            </Modal>
            {/* {!loading && <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseSnackbar} severity={`${error ? "error" : "success"}`} sx={{ width: '100%' }}>
                    {error ? "Error while sending the typeform right now, please try after sometime" : "Typeform sent successfully"}
                </Alert>
            </Snackbar>} */}
        </>
    )
}

export default OpsOperatorModal